// This file was generated by lezer-generator. You probably shouldn't edit it.
import {LRParser} from "@lezer/lr"
export const parser = LRParser.deserialize({
  version: 14,
  states: "$bQQOPOOOOOO'#Cg'#CgO]OPO'#CiOhOPO'#C^OOOO'#Ci'#CiOOOO'#Cf'#CfQQOPOOOOOO-E6e-E6eOOOO'#Cm'#CmOOOO,59T,59TOmOPO'#C`OuOPO,58xOOOO-E6d-E6dO!cOQO1G.dO]OPO'#CbOOOO'#Cd'#CdOOOO'#Ce'#CeO!jOQO'#CcOOOO'#Ca'#CaOOOO7+$O7+$OOzOQO7+$OO]OPO,58|OOOO,58|,58|OOOO,58},58}OOOO<<Gj<<GjOOOO1G.h1G.h",
  stateData: "!u~ORRO^PObSO~O^PObWOcWO~O^PO~O^PORSX~OR]O~O`^Od_Oe_Of_Og`Oh`Oi`O~O_dO~PzO_gObWOcWO~O",
  goto: "!obPPcPgjppttx!OP!_PPP!cTSOURZRQc]RhdTb]dTa]dQUOR[USQOUUVQYeQYRRe^TTOUQXQQf^QgaRie",
  nodeNames: "⚠ Content Property = PropertyName PropertyValue PlaintextValue BooleanValue True False",
  maxTerm: 25,
  skippedNodes: [0],
  repeatNodeCount: 2,
  tokenData: "&Y~RdOX!aXY!hYZ#QZ]!a]^#Q^p!apq!hq!_!a!_!`#]!`#Y!a#Y#Z#b#Z#b!a#b#c$T#c#d$d#d#h!a#h#i%R#i#m!a#m#n%n#n~!a~~&TR!hO^P`QR!oQ^P_QXY!upq!uQ!zQ_QXY!upq!u~#VQb~YZ#Q]^#Q~#bOR~R#iP^P`Q#T#U#lQ#oP#`#a#rQ#uP#g#h#xQ#{P#X#Y$OQ$TOgQR$[P^P`Q#c#d$_Q$dOhQR$kQ^P`Q#Y#Z$q#b#c$|Q$tP#Y#Z$wQ$|OiQQ%ROfQR%YP^P`Q#f#g%]Q%`P#i#j%cQ%fP#X#Y%iQ%nOdQR%uP^P`Q#X#Y%xQ%{P#g#h&OQ&TOeQ~&YOc~",
  tokenizers: [0, 1],
  topRules: {"Content":[0,1]},
  tokenPrec: 0
})

